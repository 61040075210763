import React, { useState } from 'react'
import FadeIn from 'react-fade-in'
import Layout from '../components/layout'
import Seo from '../components/seo'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ComputerIcon from '@material-ui/icons/Computer'
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode'
import NotesIcon from '@material-ui/icons/Notes'

const Contact = () => {
    const [ category, setCategory ] = useState('Account Questions')

    return (
        <div>
            <Layout>
                <Seo title="Contact" description="Contact page Ricta Browser" />
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="w-11/12 h-full p-2 m-auto text-center">
                        <h3 className="text-xl text-mainColor my-3 uppercase">To start choose your category first: </h3>
                        <FadeIn delay="100" transitionDuration="700" className="grid grid-cols-1 md:grid-cols-2 ">
                            <div onClick={()=>setCategory('Account Questions')} className="p-2 text-center cursor-pointer">
                                <div className="p-4 bg-dirtyWhite hover:bg-gray-100 rounded overflow-hidden shadow-xl  transition duration-500">
                                    <div className="mt-3 p-2">
                                        <AccountCircleIcon className="text-mainColor" style={{width:'50px',height:'50px'}}/>
                                        <div className="">
                                            <div className="space-y-5">
                                                <div className="font-bold text-lg mb-2 text-mainColor">Account Question</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div onClick={()=>setCategory('Tehnical Support')} className="p-2 text-center cursor-pointer">
                                <div className="p-4 bg-dirtyWhite hover:bg-gray-100 rounded overflow-hidden shadow-xl  transition duration-500">
                                    <div className="mt-3 p-2">
                                        <ComputerIcon className="text-mainColor" style={{width:'50px',height:'50px'}}/>
                                        <div className="">
                                            <div className="space-y-5">
                                                <div className="font-bold text-lg mb-2 text-mainColor">Technical Support</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div onClick={()=>setCategory('Feature Requests')} className="p-2 text-center cursor-pointer">
                                <div className="p-4 bg-dirtyWhite hover:bg-gray-100 rounded overflow-hidden shadow-xl transition duration-500">
                                    <div className="mt-3 p-2">
                                        <FeaturedPlayListIcon className="text-mainColor" style={{width:'50px',height:'50px'}}/>
                                        <div className="">
                                            <div className="space-y-5">
                                                <div className="font-bold text-lg mb-2 text-mainColor">Feature Requests</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div onClick={()=>setCategory('Pre-sales Questions')} className="p-2 text-center cursor-pointer">
                                <div className="p-4 bg-dirtyWhite hover:bg-gray-100 rounded overflow-hidden shadow-xl transition duration-500">
                                    <div className="mt-3 p-2">
                                        <MonetizationOnIcon className="text-mainColor" style={{width:'50px',height:'50px'}}/>
                                        <div className="">
                                            <div className="space-y-5">
                                                <div className="font-bold text-lg mb-2 text-mainColor">Pre-sales Questions</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div onClick={()=>setCategory('Bussines Development')} className="p-2 text-center cursor-pointer">
                                <div className="p-4 bg-dirtyWhite hover:bg-gray-100 rounded overflow-hidden shadow-xl transition duration-500">
                                    <div className="mt-3 p-2">
                                        <DeveloperModeIcon className="text-mainColor" style={{width:'50px',height:'50px'}}/>
                                        <div className="">
                                            <div className="space-y-5">
                                                <div className="font-bold text-lg mb-2 text-mainColor">Busssines Development/Other</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div onClick={()=>setCategory('Praise/Testimonial')} className="p-2 text-center cursor-pointer">
                                <div className="p-4 bg-dirtyWhite hover:bg-gray-100 rounded overflow-hidden shadow-xl transition duration-500">
                                    <div className="mt-3 p-2">
                                        <NotesIcon className="text-mainColor" style={{width:'50px',height:'50px'}}/>
                                        <div className="">
                                            <div className="space-y-5">
                                                <div className="font-bold text-lg mb-2 text-mainColor">Praise/ Testimonial</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FadeIn>
                    </div>
                    <div>
                        <FadeIn className="w-full flex flex-col justify-center">
                            <form action="" className="form bg-white p-6 w-full lg:w-2/3 m-auto">
                                <h3 className="text-2xl text-mainColor font-semibold">Contact Us</h3>
                                <p className="text-textColor"> To help you solve problem!</p>
                                <div className="flex flex-col justify-left mt-2">
                                    <label htmlFor="name" className="text-left mb-2 text-sm text-textColor dark:text-gray-400">What we can help you with?</label>
                                    <input type="text" name="" id="" value={category} disabled className="border p-2 text-textColor  w-full mb-3"/>
                                </div>
                                {category ==='Feature Requests' ?
                                    <>
                                        <div className="flex flex-col justify-left mt-2">
                                            <label htmlFor="name" className="text-left mb-2 text-sm text-textColor dark:text-gray-400">What is the feature you would like to suggest?</label>
                                            <input type="text" name="" id="" placeholder="Enter text" className="border p-2 text-textColor  w-full mb-3"/>
                                        </div>
                                        <div className="flex flex-col justify-left mt-2">
                                            <label htmlFor="name" className="text-left mb-2 text-sm text-textColor dark:text-gray-400">What is the pain point that feature is trying to address?</label>
                                            <input type="text" name="" id="" placeholder="Enter text" className="border p-2 text-textColor  w-full mb-3"/>
                                        </div>
                                        <div className="flex flex-col justify-left mt-2">
                                            <label htmlFor="name" className="text-left mb-2 text-sm text-textColor dark:text-gray-400">Anything else we need to know?</label>
                                            <input type="text" name="" id="" placeholder="Enter text" className="border p-2 text-textColor  w-full mb-3"/>
                                        </div>
                                    </> : null}
                                {category === 'Praise/Testimonial' ?
                                    <div className="flex flex-col justify-left my-2">
                                        <p className="text-textColor text-left font-semibold">Thank you so much for taking time to submit testimonial. For a quick note please send a message below, but if you have 3-5 minutes we&apos;d love to get an official testimonial for our site.</p>
                                    </div>
                                    :null}
                                {category ==='Account Questions' ?
                                    <>
                                        <div className="flex flex-col justify-left mt-2">
                                            <label htmlFor="name" className="text-left mb-2 text-sm text-textColor dark:text-gray-400">You can use this quick links for instant answers</label>
                                            <ul className="border p-3 text-textColor flex flex-col text-left  w-full mb-3">
                                                <li><a className="text-textColor cursor-pointer hover:text-mainColorHover">How can I view my subcription details?</a></li>
                                                <li><a className="text-textColor cursor-pointer hover:text-mainColorHover">How can I upgrade/downgrade my subscription?</a></li>
                                                <li><a className="text-textColor cursor-pointer hovet:text-mainColorHover">What happens in case of failed payment?</a></li>
                                                <li><a className="text-textColor cursor-pointer hover:text-mainColorHover">On how many computers can I use my subscription on?</a></li>
                                            </ul>
                                        </div>
                                        <p className="text-left italic mb-3 text-sm text-textColor dark:text-gray-400">Your question isn&apos;t listed above? Continue with your request</p>
                                        <div className="flex flex-col justify-left mt-2">
                                            <label htmlFor="name" className="text-left mb-2 text-sm text-textColor dark:text-gray-400">Enter email associated with your Ricta Browser accout.</label>
                                            <input type="text" name="" id="" placeholder="Enter Email" className="border p-2 text-textColor  w-full mb-3"/>
                                        </div>
                                    </>: null}
                                <div className="text-left">
                                    <label htmlFor="name" className="text-left mb-2 text-sm text-textColor dark:text-gray-400">Full Name</label>
                                </div>
                                <div className="flex space-x-5 mb-3">
                                    <input type="text" name="" id="" placeholder="Your First Name" className="border p-2  w-1/2"/>
                                    <input type="tel" name="" id="" placeholder="Your Last Name" className="border p-2 w-1/2"/>
                                </div>
                                <div className="flex flex-col justify-left mt-2">
                                    <label htmlFor="name" className="text-left mb-2 text-sm text-textColor dark:text-gray-400">Contact Email</label>
                                    <input type="email" name="" id="" placeholder="Contact Email" className="border p-2 w-full mb-3"/>
                                </div>
                                {category !=='Feature Requests' ?
                                    <textarea name="" id="" cols="10" rows="3" placeholder="Your Message" className="border p-2 mt-3 w-full"></textarea>
                                    : null}
                                <div className="flex items-baseline space-x-2 mt-2">
                                    <input type="checkbox" name="" id="" className="inline-block"/>
                                    <p className="text-gray-600 text-sm">I consent to having this website store my submitted information so they can respond to my inquiry.</p>
                                </div>
                                <input type="submit" value="Submit" className="w-full mt-6 bg-mainColor hover:bg-mainColorHover cursor-pointer text-white font-semibold p-3"/>

                            </form>
                        </FadeIn>
                    </div>
                </div>
            </Layout>
        </div>
    )

}
export default Contact